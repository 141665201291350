import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Observable } from "rxjs";
import { IAuditSnapshot } from "../../shared/model/audit-snapshot.model";

const BASE_URL: string = `${environment.apiUrl}/audit`

@Injectable({ providedIn: 'root' })
export class AuditRepository {
    constructor(private http: HttpClient) { }

    public getRequiredHours(id: string): Observable<number> {
        return this.http.get<number>(`${BASE_URL}/required-hours?id=${id}`);
    }

    public getCompletedHours(id: string): Observable<number> {
        return this.http.get<number>(`${BASE_URL}/completed-hours?id=${id}`);
    }

    public generateAudit(): Observable<IAuditSnapshot> {
        return this.http.get<IAuditSnapshot>(`${BASE_URL}`);
    }

    public getAuditSnapshot(id: string, getAllRecords: boolean = false): Observable<IAuditSnapshot> {
        return this.http.get<IAuditSnapshot>(`${BASE_URL}/snapshot?id=${id}&getAllValues=${getAllRecords}`);
    }
}