import {createAction, props} from "@ngrx/store";
import {IAuditSnapshot} from "../../../shared/model/audit-snapshot.model";
import { HttpErrorResponse } from "@angular/common/http";

export const getAuditAction = createAction(
  '[Audit] Get Audit Action',
  props<{ userId: string }>()
)

export const getAuditSuccessAction = createAction(
  '[Audit] Get Audit Success Action',
  props<{ result: IAuditSnapshot }>()
)

export const getAuditFailureAction = createAction(
  '[Audit] Get Audit Failure Action',
  props<{ error: HttpErrorResponse }>()
)

export const generateAuditAction = createAction(
  '[Audit] Generate Audit Action'
)
