import {Injectable} from "@angular/core";
import {filter, map } from 'rxjs';
import {AuditRepository} from "../repository/audit.repository";
import {select, Store} from "@ngrx/store";
import {generateAuditAction, getAuditAction} from "../store/audit/audit.actions";
import {getAuditLoadingSelector, getAuditSelector} from "../store/audit/audit.selectors";

@Injectable({ providedIn: 'root' })
export class AuditService {

    constructor(private auditRepo: AuditRepository,
                private store: Store) { }

    public getRequiredHours(id: string) {
        return this.auditRepo.getRequiredHours(id);
    }

    public getCompletedHours(id: string) {
        return this.auditRepo.getCompletedHours(id);
    }

    public generateAudit() {
        this.store.dispatch(generateAuditAction());
        return this.store.pipe(select(getAuditSelector));
    }

    //TODO: implement the getAll flag here. Should the API always return a list? This is weird.
    public getAuditSnapshot(id: string, getAll: boolean = false) {
      this.store.dispatch(getAuditAction({userId: id}));
      return this.store.pipe(select(getAuditSelector)).pipe(filter(Boolean));
    }

    public isAuditLoading() {
      return this.store.pipe(select(getAuditLoadingSelector));
    }

    public needsCSER100() {
      return this.store.pipe(
        select(getAuditSelector),
        map(audit => {
          if (!audit) {
            return false;
          } else {
            return audit.requiredCredits > 0 && !audit.auditRecs.some(record => record.description.toLowerCase().includes("introduction to cser"));
          }
        })
      )
    }
}
